/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

type MetaProps = JSX.IntrinsicElements['meta'];
const SEO: React.FC<{
  description?: string;
  lang?: string;
  meta?: MetaProps[];
  title: string;
}> = ({ description = '', lang = 'en', meta = [], title }) => {
  return (
    <StaticQuery<GatsbyTypes.SeoQueryQuery>
      query={graphql`
        query SeoQuery {
          site {
            siteMetadata {
              title
              description
              author
            }
          }
        }
      `}
    >
      {({ site }) => {
        if (!site || !site.siteMetadata) return null;

        const metaDescription = description || site.siteMetadata.description;

        const defaultMeta: MetaProps[] = [
          {
            property: `og:title`,
            content: title,
          },

          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },

          {
            name: `twitter:title`,
            content: title,
          },
        ];
        if (metaDescription) {
          defaultMeta.push(
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
          );
        }
        if (site.siteMetadata.author) {
          defaultMeta.push({
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          });
        }
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`${site.siteMetadata.title} - %s`}
            meta={defaultMeta.concat(meta)}
          />
        );
      }}
    </StaticQuery>
  );
};

export default SEO;
